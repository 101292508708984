nav{
    height: 60px;
    width: 100%;
    display: flex;
    position: fixed;
    z-index: 10;
    top: 0;
    background-color: rgba(0,0,0,0.3);
}

/*Styling logo*/
.logo{
    display: flex;
    align-items: center;
}
.logo-btn{
    background-color: #fff;
    width: 36px;
    height: 36px;
    border-radius: 6px;
    padding: 1px;
    margin: 0 10px 0 15px;
    border: 2px #cfcfcf solid;
}
.logo img {
    height: 100%;
    max-height: 60px;
    width: 100%;
    max-width: 60px;
    padding: 3px 0;
}

/*Styling Links*/
.nav-links{
    display: flex;
    list-style: none; 
    width: 100%;
    padding: 0 0.7vw;
    justify-content: space-between;
    text-transform: uppercase;
}
.nav-buttons,
.nav-left,
.nav-right{
    display: flex;
    align-items: center;
    box-sizing:content-box;
}
.nav-right{
    justify-content: flex-end;
}
.nav-links li a{
    text-decoration: none;
    margin: 0 0.7vw;
    color: #fff;
    opacity: 0.8;
}
.nav-links li a.active,
.nav-links li a:hover {
    opacity: 1;
}
.nav-links li {
    position: relative;
}
.nav-links li a::before {
    content: "";
    display: block;
    height: 3px;
    width: 0%;
    background-color: #fefefe;
    position: absolute;
    transition: all ease-in-out 250ms;
    margin: 0 0 0 10%;
}
.nav-links li a.active::before,
.nav-links li a:hover::before{
    width: 80%;
}

/*Styling Buttons*/
.login-button{
    color: #fff;
    border-radius: 6px;
    padding: 0.4rem 1.3rem;
    margin-right: 10px;
    font-size: 1rem;
    cursor: pointer;
    background-color: var(--btn-border-blue);
    border-color: var(--btn-blue);
    border-width: 3px;
}
.login-button:hover {
    background-color: var(--btn-hover-blue);
    /* transition: all ease-in-out 350ms; */
}
.join-button{
    color: #fff;
    border-width: 3px;
    background-color: var(--btn-green);
    border-color: var(--btn-border-green);
    border-radius: 6px;
    padding: 0.4rem 1.3rem;
    font-size: 1rem;
    cursor: pointer;
}

.join-button:hover {
    background-color: var(--btn-hover-green);
    /* transition: all ease-in-out 350ms; */
}

/*Styling Hamburger Icon*/
.hamburger div{
    width: 30px;
    height:3px;
    background: #ffffff;
    margin: 5px;
    transition: all 0.3s ease;
}
.hamburger.toggle div{
    background: #000;
}
.nav-buttons,
.hamburger{
    display: none;
}

/*Stying for small screens*/
@media screen and (max-width: 1024px){
    .nav-buttons{
        display: flex;
    }
    .hamburger{
        display:block;
        position: absolute;
        cursor: pointer;
        right: 5%;
        top: 50%;
        transform: translate(-5%, -50%);
        z-index: 2;
        transition: all 0.7s ease;
    }
    .nav-links{
        position: absolute;
        background: #fff; 
        clip-path: circle(50px at 100% -10%);
        -webkit-clip-path: circle(50px at 110% -10%);
        transition: all 1s ease-out;
        pointer-events: none;
        left: 0;
    }

    .nav-left{
        height: 100vh;
        width: 100%;
        justify-content: initial;
        flex-direction: column;
    }
    .nav-right{
        display: none;
    }
    .nav-links.open{
        clip-path: circle(1000px at 90% -10%);
        -webkit-clip-path: circle(1000px at 90% -10%);
        pointer-events: all;
    }
    .nav-links li{
        opacity: 0;
        padding: 10px 0;
    }
    .nav-links li a{
        color: #000;
    }
    .nav-links li:nth-child(1){
        transition: all 0.5s ease 0.2s;
    }
    .nav-links li:nth-child(2){
        transition: all 0.5s ease 0.4s;
    }
    .nav-links li:nth-child(3){
        transition: all 0.5s ease 0.6s;
    }
    .nav-links li:nth-child(4){
        transition: all 0.5s ease 0.7s;
    }
    .nav-links li:nth-child(5){
        transition: all 0.5s ease 0.8s;
    }
    .nav-links li:nth-child(6){
        transition: all 0.5s ease 0.9s;
        margin: 0;
    }
    .nav-links li:nth-child(7){
        transition: all 0.5s ease 1s;
        margin: 0;
    }
    .nav-links li:nth-child(8){
        transition: all 0.5s ease 1.1s;
        margin: 0;
    }
    li.fade{
        opacity: 1;
    }
}
/*Animating Hamburger Icon on Click*/
.toggle .line1{
    transform: rotate(-45deg) translate(-5px,6px);
}
.toggle .line2{
    /* transition: all 0.7s ease; */
    /* width:0; */
    opacity: 0;
}
.toggle .line3{
    transform: rotate(45deg) translate(-5px,-6px);
}