.block-404_error{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    /* background: url("./../../assets/visitor/img/home/map.svg"); */
    background-color: bisque;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    color: #000;
    font-family: 'Source Serif Pro',serif;
    text-align: center;
    justify-content: center;
    align-items: center;
}