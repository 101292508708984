.login-pattern{
    width: 100%;
    height: 100%;
    position: fixed;
    background: url("./../../assets/visitor/img/home/bg.svg");
    background-repeat: repeat;
    background-size: 600px;
    background-repeat: repeat;
    z-index: -1;
    opacity: 0.1;
}

.login-section::before{
    content: "";
    position: fixed;
    z-index: -2;
    top: 0;
    left: 0;
    width: 600%;
    height: 100%;
    bottom: 0;
    background: linear-gradient(45deg, #1772C6, #45b0e9, #1772C6, #45b0e9);
    background-size: 100% 100%;
    background-repeat: no-repeat no-repeat;
    background-position: left top;
    animation: gradient 10s linear infinite alternate;
}

.login-section{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    font-family: 'Source Serif Pro',serif;
    text-align: center;
    align-items: center;
}

.login-frame{
    background: #FFFFFF;
    box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    padding: 30px;
    width: 90%;
    max-width: 420px;
    margin-top: 150px;
}

.login-email_input,
.login-password_input{
    border-radius: 8px;
    border: #C6C6C6 dashed 2px;
    text-align: center;
    font-size: 16px;
    cursor: pointer;
}
.login-btn-login{
    border-radius: 8px;
    color: white;
    font-weight: bold;
    background-color: var(--btn-border-blue);
    border-color: var(--btn-blue);
    border-width: 3px;
}
.login-btn-login:hover{
    background-color: var(--btn-hover-blue);
}
ul:focus{
    color: white;
}
li:focus{
    color: white;
}

p:focus{
    color: white;
}