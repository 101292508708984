.how_it_works-section{
    padding: 100px 0 0 0;
    /* min-height: 100vh; */
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;

    display: flex;
    flex-direction: column;

    /* background: url("./../../assets/visitor/img/home/map.svg"); */
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    font-family: 'Source Serif Pro',serif;
}

.how_it_works-role_selector{
    display: flex;
    margin: 20px 0 10px 0;
}

.how_it_works-role_main{
    margin: 0 5px 0 0;
    cursor: pointer;
    width: 120px;
    text-align: center;
}
.how_it_works-role_main:hover{
    border-bottom: 1px solid #000;
}
.how_it_works-role_main.active{
    font-weight: bold;
    border-bottom: 1px solid #000;
}


.how_it_works-role_info{
    padding: 10px 0 80px 0;
}




/* PLAYER */
.react-player{
    margin-top: 20px;
}
.how_it_works-section .react-player video{
    max-height: 300px;
}

.how_it_works-section .swiper{
    height: 100%;
}