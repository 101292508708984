.main{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    background: url("./../../assets/visitor/img/home/map.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    color: #fff;
    font-family: 'Source Serif Pro',serif;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.test{
    position: relative;
    background-color: rgba(110, 110, 110, 0.789);
    max-height: 250px;
    max-width: 700px;
    width: 100%;
    padding: 50px;
    border-radius: 50px;
    box-shadow: 3px 3px 15px 32px rgba(255, 255, 255,0.3);
    border: 5px solid rgba(255, 255, 255, 0.7);
    display: flex;
    flex-direction: column;
    justify-content: center;
}



.company_slogan{
    font-style: italic;
    font-size: 33px;
    font-weight: 500;
    margin-bottom: 25px;
}
.company_title_general{
    font-size: 100px;
    font-weight: 800;
    line-height: 0.9;
    margin-top: 20px;
}
.company_title_arc{
    color: #fff;
}
.company_title_teach{
    color: #FF4136;
}




