html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 14px;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
body::before{
	content: "";
	width: 100%;
    height: 100%;
	position: fixed;
    z-index: -2;

	background-image: linear-gradient(to right top, #9cd7ff, #71bff7, #66bbf8, #47acf4, #219bf1, #0074e7, #004ad4, #130cb7);
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

p {
  font-family: Poppins, Helvetica, sans-serif;
  font-size: 14px;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* CUSTOM */
.company-name-arc-color{
	color: #000;
}
.company-name-teach-color{
	color: red;
}

:root{
	--btn-blue: #347FEF;
	--btn-border-blue: #1772C6;
	--btn-hover-blue: #035aac;

	--btn-green: #119883;
	--btn-border-green: #127B6B;
	--btn-hover-green: #0a5147;

	--btn-red: #BB2241;
	--btn-border-red: #86152C;
	--btn-hover-red: #5b0d1d;

	--btn-yellow: #c8ad00;
	--btn-border-yellow: #fff9b5;
	--btn-hover-yellow: #b7a801;
	--btn-inactive-yellow: #cbc7a3;
}

@keyframes gradient {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-400vw);
      /* 5/6x100% */
    }
} 


.subheader-title,
.subheader-title span{
    font-size: 24px;
    font-weight: bold;
}
