.elements-character::before{
    content: "";
    width: 200px;
    height: 400px;
    
    position: absolute;
    z-index: -1;
    opacity: 0.1;
}
.elements-character.fire.man::before{
    background: url("./../../assets/elements/fireMan.svg");
    background-repeat: no-repeat;
    background-position: 30% 100%;
    background-size: contain;
}
.elements-character.fire.woman::before{
    background: url("./../../assets/elements/fireWoman.svg");
    background-repeat: no-repeat;
    background-position: 30% 100%;
    background-size: contain;
}

.elements-character.water.man::before{
    background: url("./../../assets/elements/waterMan.svg");
    background-repeat: no-repeat;
    background-position: 30% 100%;
    background-size: contain;
}
.elements-character.water.woman::before{
    background: url("./../../assets/elements/waterWoman.svg");
    background-repeat: no-repeat;
    background-position: 30% 100%;
    background-size: contain;
}

.elements-character.earth.man::before{
    background: url("./../../assets/elements/earthMan.svg");
    background-repeat: no-repeat;
    background-position: 30% 100%;
    background-size: contain;
}
.elements-character.earth.woman::before{
    background: url("./../../assets/elements/earthWoman.svg");
    background-repeat: no-repeat;
    background-position: 30% 100%;
    background-size: contain;
}

.elements-character.air.man::before{
    background: url("./../../assets/elements/airMan.svg");
    background-repeat: no-repeat;
    background-position: 30% 100%;
    background-size: contain;
}
.elements-character.air.woman::before{
    background: url("./../../assets/elements/airWoman.svg");
    background-repeat: no-repeat;
    background-position: 30% 100%;
    background-size: contain;
}

/* ANIMALS */
.elements-animal::before{
    content: "";
    width: 220px;
    height: 220px;
    
    position: absolute;
    z-index: -1;
    opacity: 0.1;
}
.elements-animal.fox::before{
    background: url("./../../assets/elements/fireAnimal.svg");
    background-repeat: no-repeat;
    background-position: 0% 100%;
    background-size: contain;
}
.elements-animal.wolf::before{
    background: url("./../../assets/elements/waterAnimal.svg");
    background-repeat: no-repeat;
    background-position: 0% 100%;
    background-size: contain;
}
.elements-animal.panda::before{
    background: url("./../../assets/elements/earthAnimal.svg");
    background-repeat: no-repeat;
    background-position: 0% 100%;
    background-size: contain;
}
.elements-animal.eagle::before{
    background: url("./../../assets/elements/airAnimal.svg");
    background-repeat: no-repeat;
    background-position: 0% 100%;
    background-size: contain;
}

.elements-animal{
    width: 100%;
    max-width: 320px;
    height: 360px;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    padding: 20px;
    justify-content: center;
    border-radius: 20px;
    box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.25);

    background: linear-gradient(46deg, rgba(143, 222, 253,0.5), rgb(255, 222, 239,0.5), rgb(167, 220, 255,0.5));
    background-size: 600% 600%;

    z-index: 1;
}









.elements-section{
    padding: 100px 0 60px 0;
    min-height: 100vh;
    width: 90%;
    max-width: 900px;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    font-family: 'Source Serif Pro',serif;
    text-align: center;
    align-items: center;
}



.elements-block{
    width: 100%;
}

.elements-character{
    width: 100%;
    max-width: 320px;
    height: 420px;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    padding: 20px;
    justify-content: center;
    border-radius: 20px;
    box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.25);

    background: linear-gradient(46deg, rgba(143, 222, 253,0.5), rgb(255, 222, 239,0.5), rgb(167, 220, 255,0.5));
    background-size: 600% 600%;

    z-index: 1;
}

.elements-character img{
    max-width: 100%;
    max-height: 100%;
    padding: 10px;
}






/* ELEMENT ICONS */
.test2{
    max-height: 120px;
    max-width: 375px;
    background-color: rgba(255, 255, 255, 0.19);
    padding: 10px 25px;
    border-radius: 25px;
    box-shadow: 3px 3px 15px 5px rgba(0, 0, 0, 0.4);
    border: 5px solid rgba(255, 255, 255, 0.7);
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}

.element-icon{
    width: 60px;
    height: 60px;
    margin: 5px;
    border-radius: 30px;
    box-shadow: 3px 3px 25px 12px rgba(0,0,0,0.1);
    border: 5px solid rgba(255, 255, 255, 0.465);
    cursor: pointer;
}

svg.element-icon-inactive path,
svg.element-icon-inactive circle{
    fill: #ddd !important;
}
svg.element-icon-inactive ellipse{
    fill: #c6c6c6 !important;
}

.element-btn-gender{
    color: #fff;
    border-radius: 6px;
    padding: 0.4rem 1.3rem;
    margin-right: 10px;
    font-size: 1rem;
    cursor: pointer;
    background-color: var(--btn-inactive-yellow);
    border-color: var(--btn-border-yellow);
    border-width: 3px;
    width: 110px;
}

.element-btn-gender.active{
    background-color: var(--btn-yellow);
}

.element-btn-gender:hover{
    background-color: var(--btn-hover-yellow);
}

.element-btn-animal{
    color: #fff;
    border-radius: 6px;
    padding: 0.4rem 0.6rem;
    margin-right: 10px;
    font-size: 1rem;
    cursor: pointer;
    background-color: var(--btn-inactive-yellow);
    border-color: var(--btn-border-yellow);
    border-width: 3px;
    width: 60px;
}
.element-btn-animal.active{
    background-color: var(--btn-yellow);
}

.element-btn-animal:hover{
    background-color: var(--btn-hover-yellow);
}














.swiper {
    width: 100%;
    height: 100vh;
  }
  
  .swiper .swiper-slide {
    text-align: center;
    font-size: 18px;
    width: 100%;
    height: 100%;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    object-fit: contain;
  }
  
  .swiper-pagination-bullet {
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    font-size: 12px;
    color: #000;
    opacity: 1;
    background: rgba(0, 0, 0, 0.2);
  }
  
  .swiper-pagination-bullet-active {
    color: #fff;
    background: #007aff;
  }