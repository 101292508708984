.faq-section{
    padding: 100px 0 60px 0;
    min-height: 100vh;
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
}

.faq-accordion {
   width: 90%;
   margin: auto;
}

.title {
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: lighter;
    position: relative;
    padding: 10px;
    border-radius: 4px;
    margin-top: 13px;
    transition: all .2s ease-in;

    background: #FFFFFF;
    box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}

.title-text {
  margin-left: 10px;
}

.title:hover {
  cursor: pointer;
}

.title.active {
  background-color: rgb(255, 255, 255);
  border-radius: 10px 10px 0 0;
}

.content {
  z-index: 1;
  background-color: transparent;
  border-radius: 4px;
  font-size: 14px;
  text-align: center;
  position: relative;
  margin-top: -30px;
  text-align: left;
  transition: all 200ms ease-in;
  height: 30px;
}

.content-open {
  margin-top: 0px;
  background-color: rgba(255,255,255, .8);
  transition: all 350ms ease-out;
  border-radius: 0 0 10px 10px;
  height: auto;
  box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.25);
}

.content-text {
  padding: 15px;
  visibility: hidden;
  opacity: 0;
  overflow: auto;
  /* transition: all .2s ease-in; */
}

.content-text-open {
  visibility: visible;
  opacity: 1;
  transition: all .8s ease-in;
}

.icon {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.5);
  transition: all .3s ease-in-out;
}

.icon-rotate-90 {
  transform: rotate(90deg);
}

.arrow-wrapper {
  position: absolute;
}

