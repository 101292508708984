.aboutUs-section{
    padding: 100px 0 0 0;
    /* min-height: 100vh; */
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;

    display: flex;
    flex-direction: column;

    /* background: url("./../../assets/visitor/img/home/map.svg"); */
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    font-family: 'Source Serif Pro',serif;
}

.aboutUs-type_selector{
    display: flex;
    margin: 20px 0 10px 0;
}

.aboutUs-type_info{
    padding: 10px 0 80px 0;
}


.aboutUs-typeNav{
    margin: 0 5px 0 0;
    cursor: pointer;
    width: 120px;
    text-align: center;
}
.aboutUs-typeNav:hover{
    border-bottom: 1px solid #000;
}
.aboutUs-typeNav.active{
    font-weight: bold;
    border-bottom: 1px solid #000;
}


.aboutUs-office{
    background-color: rgba(0,0,0,0.1);
    border-radius: 10px;
    padding: 15px 15px 10px 15px;
}
.aboutUs-office img{
    border-radius: 10px;
}
.aboutUs-office figcaption{
    font-size: 16px;
    text-align: center;
    padding: 10px 0 0 0;
}


/* SWIPER */
.aboutUs-section .swiper{
    height: 100%;
}
.aboutUs-section .swiper-slide{
    text-align: initial;
}

/* TIMELINE */
.aboutUs-section .timeline-item-title{
    text-align: center;
}
.aboutUs-section .timeline-card-content .show-less{
    max-height: initial;
}