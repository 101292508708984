.price-section{
    padding: 100px 0 0 0;
    /* min-height: 100vh; */
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;

    display: flex;
    flex-direction: column;

    /* background: url("./../../assets/visitor/img/home/map.svg"); */
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    font-family: 'Source Serif Pro',serif;
}

.price-type_selector{
    display: flex;
    justify-content: center;
    margin: 30px 0 100px 0;
}

.price-btn{
    border-radius: 8px;
    font-weight: bold;
    background-color: var(--btn-inactive-yellow);
    border-color: var(--btn-border-yellow);
    border-width: 3px;
    padding: 0.4rem 1.3rem;
    margin-bottom: 10px;
}
.price-btn.active{
    background-color: var(--btn-yellow);
}
.price-btn:hover{
    background-color: var(--btn-hover-yellow);
}

.price-btn-buy{
    border-radius: 8px;
    font-weight: bold;
    background-color: var(--btn-green);
    border-color: var(--btn-border-green);
    border-width: 3px;
    padding: 0.4rem 1.3rem;
    margin-bottom: 10px;
}
.price-btn-buy:hover{
    background-color: var(--btn-hover-green);
    color: #fff;
}


.price-section .swiper{
    height: 100%;
    width: 100%;
    margin-top: 10px;
}

.price-section .swiper-slide{
    padding: 20px 0 40px 0;
    width: 50%;
}

.price-section .swiper-slide.device{
    padding: 20px 0 40px 0;
    width: 50%;
}

.price-section .swiper-slide.mobile{
    padding: 20px 0 40px 0;
    width: 85%;
}

.price-type_block{
    box-sizing: border-box;
    width: 370px;
    height: 450px;
    background: #FEFEFE;
    border: 3px solid #D8D8D8;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    padding: 25px 10px;
    margin: 0 10px;
}
.swiper-slide.device .price-type_block,
.swiper-slide.mobile .price-type_block{
    width: 100%;
    margin: 0;
}
.price-points{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0;
}

.check{
    color: #a2a2a2;
    margin-right: 10px;
}

.check.included{
    color: #00933b;
}