.portal-nav-main{
    background-color: #1e1e2d;
    z-index: 1;
    height: 50px;
    width: calc( 100% - 70px );
    align-items: center;
    padding: 0 10px;
    right: 0;
    transition: width .3s;
}

.portal-nav-icon{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    height: 30px;
    width: 30px;
    border-radius: 5px;
    margin: 0 5px;
    cursor: pointer;
}

.portal-nav-icon:hover{
    background-color: rgba(255, 255, 255, 0.2);
}

.portal-nav-btn-aside{
    display: none;
}

@media screen and (max-width: 1024px){
    .portal-nav-main{
        width: 100%;
        transition: width .3s;
    }
    .portal-nav-main.aside-fixed{
        width: calc( 100% - 263px );
        transition: width .3s;
    }
    .portal-nav-btn-aside{
        display: flex;
    }
}