.block-aside-main{
    width: 70px;
    height: 100%;
    background-color: #1e1e2d;
    position: fixed;
    left: 0;
    top: 0%;
    bottom: 0;
    z-index: 2;
}

/* HEADER */
.block-aside-header{
    height: 50px;
    background-color: #1a1a27;
}

.block-aside-company-name{
    display: none;
}

/* BODY */
.block-aside-menu_item,
.block-aside-menu_subitem{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 35px;
    margin: 0 0 1px 0;
}

.block-aside-menu_item svg{
    color: #a5a5a5;
}

.block-aside-menu_item.active svg,
.block-aside-menu_subitem.active svg{
    color: #3699FF;
}

.menu-item-arrow{
    transition: transform .3s;
}
li.open .menu-item-arrow{
    transform: rotate(90deg);
}

.menu-item-content{
    max-height: 0;
    overflow: hidden;
}

.block-aside-main.open li.open .menu-item-content{
    max-height: 100%;
}
/* OPEN */
.block-aside-main.open{
    width: 265px;
}

.block-aside-main.open .block-aside-company-name{
    display:initial;
}

@media screen and (max-width: 1024px){
    .block-aside-main{
        left: -70px;
    }
    
    .block-aside-main.open{
        width: 265px;
        left: 0;
    }

}