.offCanvas-user_profile {
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1;
    width: 300px;
    height: 100%;
    padding: 40px;
    background-color: rgb(254, 253, 238);
    box-shadow: 0 0 15px rgba(0,0,0,0.2);
    transition: all 0.3s cubic-bezier(0.7,0,0.3,1);
    transform: translate3d(360px,0,0);
  }
  .offCanvas-user_profile.active {
    transform: translate3d(0px,0,0);
  }

  .offcanvas-close-button{
    position: absolute;
    right: 25px;
    top: 25px;
    padding: 5px;
    border-radius: 50%;
  }

  .offcanvas-close-button:hover{
    background-color: rgba(0,0,0,0.5);
  }
  .offcanvas-close-button:hover svg path{
    stroke: #fff;
  }